


.digestive-container {
    width: 100%;
    margin-bottom: 20px;
    padding: 8px 12px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.digestive-container label {
    font-size: 1rem;
    color: #555;
    display: block;
    margin-bottom: 8px;
}

.digestive-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.digestive-symptoms p {
    margin-bottom: 8px;
    font-size: 0.95rem;
    color: #333;
}

.digestive-symptom-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.digestive-symptom-option input[type="checkbox"] {
    appearance: none;
    width: 24px; 
    height: 24px; 
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 10px;
    outline: none;
}

.digestive-symptom-option input[type="checkbox"]:focus {
    outline: 2px solid #5cb85c;
}

.digestive-symptom-option input[type="checkbox"]:checked {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.digestive-symptom-option input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    left: 6px; 
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

