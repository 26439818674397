/*
Admin Page Styling
*/

.adminpage-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.adminpage-navbar {
  background-color: #65295f;
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
}

.adminpage-navbar-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.adminpage-profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.adminpage-main-content {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  align-items: stretch;
}

.adminpage-main-content::-webkit-scrollbar-thumb {
  background-color: #65295f;
  border-radius: 4px;
}

.adminpage-main-content::-webkit-scrollbar-thumb:hover {
  background-color: #843a75;
}

.adminpage-sidebar {
  background-color: #65295f;
  width: 100%;
  color: white;
  padding: 20px;
  height:100%;
  align-items: center;
}

.adminpage-search-box input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline: none;
  margin-bottom: 15px;
  font-size: 16px;
  background-color: white;
  color: black;
}

.adminpage-search-box {
  margin-top: 10px;
}

.adminpage-user-list-box {
  background-color: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  max-height:80vh;
  overflow:scroll;
}

.taskpage-user-list-box {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  max-height: 600px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  flex-grow: 1;
}

.adminpage-user-list {
  overflow-y: scroll;
}

.adminpage-user-box {
  background-color: #65295f;
  color: white;
  border: none;
  padding-top: 15px;
  width:95%;
  cursor: pointer;
  transition: background-color 0.3s;
  overflow-x:hidden;
}

.adminpage-checkbox {
  width:5%;
}

.adminpage-user-box:hover {
  background-color: rgb(151, 60, 190);
}


.adminpage-user-list-box::-webkit-scrollbar {
  width: 8px;
}

.adminpage-user-list-box::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.adminpage-user-list-box::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.adminpage-info-box {
  background-color: #d3d3d3;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.adminpage-info-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.adminpage-info-divider {
  border: none;
  border-top: 10px solid #cd8466;
  margin: 20px 0;
}

.adminpage-info-details {
  display: flex;
  justify-content: space-between;
}

.adminpage-info-column {
  flex-basis: 45%;
}

.adminpage-info-column p {
  margin: 5px 0;
}

.adminpage-create-task-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
  max-height: 80px;
}

.adminpage-create-task-button:hover {
  background-color: #45a049;
}

.adminpage-task-form {
  max-height: 100%; /* Use full available space */
}

.task-assignment-options {
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
}

.task-assignment-options label {
  font-size: 18px;
  color: #333;
}

.adminpage-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.adminpage-submit-button {
  background-color: #65295f;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.adminpage-submit-button:hover {
  background-color: rgb(151, 60, 190);
}

.adminpage-user-box.selected {
  background-color: rgb(151, 60, 190);
}


.adminNav:enabled{
    background-color: #65295f !important;
    border-color: #65295f !important;
    color: white;
    border:none;
}

.adminNav:hover{
    background-color: #50204a !important;
    border-color: #50204a !important;
    color: white;
    border:none;
}

.adminNav{
    background-color: #50204a !important;
    color: white;
    border:none;
    min-height:40px;
    
}

.adminGroup{
    background-color: #65295f !important;
    color: white;
    border:none;
    min-height:40px;
    margin-right: 5px;
}

.adminGroup:hover{
  background-color: #50204a !important;
  color: white;
  border:none;
  min-height:40px;
}

.adminGroupCreate{
  background-color: #cb9144 !important;
  border-color: #cb9144 !important;
  color: white;
  margin-bottom: 15px;
}

.adminGroupCreate:hover{
  background-color: #a37538 !important;
  border-color: #a37538 !important;
  color: white;
  margin-bottom: 15px;
}

.adminGroupDelete{
    background-color: #b0080d !important;
    color: white;
    border:none;
    min-height:40px;
    margin-right: 10px;
}

.adminGroupDelete:hover{
  background-color: #6a070a !important;
  color: white;
  border:none;
  min-height:40px;
}


.adminpage-userlist-infocard{
    background-color: white;
    padding:5px;

}

.adminpage-userlist-infocard-header{
    background-color: white;
    padding:0px;
    margin:0px;
    font-weight:bold;
    border-bottom: 2px solid black;
}

.adminpage-userlist-infocard-item{
    background-color: white;
    padding:0px;
    margin:0px;
    border-bottom: 1px solid black;
}
    
    
.adminpage-details-button{
    background-color: #50204a !important;
    color: white;
    border:none;
    min-height:40px;
}

.adminpage-details-button:enabled{
    background-color: #65295f !important;
    border-color: #65295f !important;
    color: white;
    border:none;
}
