/* edited bootstrap */
/* purple */
.btn-success {
    background-color: white !important;
    border-color: #813579 !important;
    color: black;
}

.btn-success:hover {
    background-color: #652a5f !important;
    border-color: #652a5f !important;
    color: white;
}

.btn-success:active {
    background-color: #652a5f !important;
    border-color: #652a5f !important;
    color: white;
}

/* gold */
.btn-danger {
    background-color: white !important;
    border-color: #cb9144 !important;
    color: black;
}

.btn-danger:hover {
    background-color: #a37538 !important;
    border-color: #a37538 !important;
    color: white;
}

.btn-danger:active {
    background-color: #a37538 !important;
    border-color: #a37538 !important;
    color: white;
}

/* white */
.btn-warning {
    background-color: white !important;
    border-color: white !important;
    color: black;
}

.btn-warning:hover {
    background-color: #e4e0e0 !important;
    border-color: #e4e0e0 !important;
    color: black;
}

.btn-warning:active {
    background-color: #e4e0e0 !important;
    border-color: #e4e0e0 !important;
    color: black;
}

.shadow-light {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow-heavy {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.shadow-light-negative {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
}


.generalButtonTask {
    border-width: 4px;
    border-radius: 10px;
    padding: 10px;
    overflow:hidden;
    background-color: #f1f1f1;
}

.generalButtonTask:hover {
    color:#652a5f;
    background-color: white
}

.task{
    outline: #813579 solid 4px;
}

.question{
    outline: #cb9144 solid 4px;
}

/* generalButton */
.generalButton {
    border: 3px solid;
    border-radius: 10px;
    padding: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
}


/* imageButton */
.imageButton {
    min-width:150px;
    aspect-ratio: 1 / 1;
    background-color:#813579;
    border-width: 4px;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    display: flex;
    align-items: flex-end;
}


/* widths */
.w100 {
    width: 100%;
}
.w95 {
    width: 95%;
}
.w80 {
    width: 80%;
}
.w50 {
    width: 50%;
}
.w30 {
    width: 30%;
}
.w20 {
    width: 20%;
}
.w10 {
    width: 10%;
}

/* widths */
.h-1 {
    height: 30px;
}
.h-2 {
    height: 60px;
}
.h-3 {
    height: 90px;
}
.h-4 {
    height: 120px;
}
.h-5 {
    height: 150px;
}


.p-0 {
    padding: 0%;
    padding-left: 0%;
}
.p-1 {
    padding: 10%;
}
.p-2 {
    padding: 20%;
}
.p-5 {
    padding: 50%;
}
.p-8 {
    padding: 80%;
}
.p-10 {
    padding: 100%;
}


/* fontSizes */
.fs {
    font-size: small;
}
.fm {
    font-size: medium;
}
.fl {
    font-size: large;
}
.fxl {
    font-size: x-large;
}
.fxxl {
    font-size: xx-large;
}




/*  other */
.accountpage-button{
    min-width:150px;
    aspect-ratio: 1 / 1;
}

.accountRedeem-captionTwo{
    background: linear-gradient(0deg, rgba(129,53,121,1) 0%, rgba(129,53,121,0.8953782196472339) 50%, rgba(101,41,95,0) 100%);  
    width:100%;
    text-decoration: none;
    display: flex;
}

.marginRight{
    margin-right: 15px;
}
.marginBottom{
    margin-bottom: 15px;
}

.ButtonTaskParagraph{
    text-align: left;
}

.ButtonTaskParagraphAlignRight{
    text-align: right;
}

.FoodPage-no-left-rounding {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.FoodPage-no-right-rounding {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}


.TextInput {
    border-radius: 0;
    border: none;
    border-bottom: 3px solid white;
    padding: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #813579;
}

.TextInput:focus {
    border-radius: 0;
    border: none;
    border-bottom: 5px solid #813579;
    padding: 5px;
    outline: none;
}

.Login-text-changes {
    height: 60px;
}

.Login-button-changes {
    padding: 8px;
    font-size: 18px;
}


.registartion-text-changes {
    height: 60px;
    margin-bottom: 10px;
}
/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: whitesmoke;
    border:rgb(129, 53, 121) solid 4px ;
    border-radius: 10px;
    width: 100%;
    max-width: 400px; 
    text-align: center;
    color: black;
    position: relative;
}

