.feedback-widget-container {
    position: relative;
}

.feedback-icon {
    cursor: pointer;
    color: #813579;
    font-size: 1.5rem;
    display: inline-block;
    position: relative;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.feedback-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9998;
}

.feedback-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
    z-index: 9999;
}

.feedback-popup-header h4 {
    margin: 0;
}

.feedback-popup-body {
    margin-top: 15px;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
}

.feedback-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.feedback-item:last-child {
    border-bottom: none;
}

.feedback-popup-footer {
    text-align: right;
}

/* monika styles */

.monika-feedback-input-container {
    position: relative;
}

.monika-feedback-icon {
    cursor: pointer;
    color: #813579;
    font-size: 1.5rem;
    display: inline-block;
}

.monika-feedback-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9998;
}

.monika-feedback-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.monika-feedback-popup-header h4 {
    margin: 0;
}

.monika-feedback-popup-body {
    margin-top: 15px;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-y: auto;
}

.monika-feedback-popup-footer {
    text-align: right;
}
