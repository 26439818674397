.sleep-container{
    width: "100%";
    max-width: "100%";
    height: 420px;
    margin: "0 auto";
    margin-bottom: 100px;
    box-sizing: "border-box";
    background: "linear-gradient(135deg, #f0f4f8, #ffffff)";
    box-shadow: "0 6px 20px rgba(0, 0, 0, 0.15)";
    font-family: 'Roboto', sans-serif;
}

.sleep-inner-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}