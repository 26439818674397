

.icon-container{

    border-radius: 999px;
    width: 54px; /*Icon height plus two times padding*/
    height: 54px;
    padding: 7px;
    
}

.container-border{
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.15);
}