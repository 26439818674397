

.food-event {
  background: #fae3f7;
  color: black;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  border-left: 5px solid #813579;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  /* position: relative; */
}

.food-event strong {
  font-size: 16px;
}

.food-event .time-range {
  font-size: 12px;
  font-weight: bold;
  color: black;
}

.food-event .description {
  font-size: 12px;
  color: black;
  margin-top: 5px;
}

.dfg-min-height-container{
  min-height: 400px;
}

.dfg-icon{
  height: 25px;
  width: 25px;
  margin: 5px;
}