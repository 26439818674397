body {
  margin: 0;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F5F1;

}

.pad-sides-16{
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.homepage-max-width{
  max-width: 768px;
  margin: auto;
}
.account-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 768px;
  margin: auto;
  padding: 16px;
  background: linear-gradient(145deg, #fdfdfd, #f0f0f0);
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  min-height: 250px;
  margin-bottom: 40px;
}

.mainContent{
  min-height: 90vh;
}

.text-purple{
  color: #652a5f;
} 

.text-gold{
  color: #cb9144;
}

.bg-purple{
  background-color: #652a5f;
}

.bg-gold{
  background-color: #cb9144;
}

Link{
  text-decoration: none!important;
}

.btn-success {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #813579 !important;
  color: black;
}

.btn-success:hover {
  background-color: #652a5f !important;
  border-color: #652a5f !important;
  color: white;
}

.btn-success:active {
  background-color: #652a5f !important;
  border-color: #652a5f !important;
  color: white;
}

/* gold */
.btn-danger {
  background-color: white !important;
  border-color: #cb9144 !important;
  color: black;
}

.btn-danger:hover {
  background-color: #a37538 !important;
  border-color: #a37538 !important;
  color: white;
}

.btn-danger:active {
  background-color: #a37538 !important;
  border-color: #a37538 !important;
  color: white;
}

/* white */
.btn-warning {
  background-color: white !important;
  border-color: white !important;
  color: black;
}

.btn-warning:hover {
  background-color: #e4e0e0 !important;
  border-color: #e4e0e0 !important;
  color: black;
}

.btn-warning:active {
  background-color: #e4e0e0 !important;
  border-color: #e4e0e0 !important;
  color: black;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerStyle{
  background-color:white;
  outline: solid 4px #cb9144;
  height:60px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top:0;
  width: 100%;
}
.footerStyle{
  background: linear-gradient(to top, #50204a 0%,#65295f 73%,#65295f 73%,#65295f 100%);
  border-top: solid 4px #cb9144;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.nav-link{
  font-size:1.5em;
  font-weight: bold;
  color:black;
}

.headerMaxWidth{
  max-width: 768px;
  text-decoration: none;
}

.headerName{
  color:#813579; font-weight:bold;
  font-size:1.5em;

}

.headerName:hover{
  color:black; font-weight:bold;
}

.pointsSize{
  font-size: 120px;
}
.pointsTextSize{
  font-size: 30px;
}





.bottom-border-yellow
.accountpage-div{
  border-bottom:3px solid #cb9144; border-radius:1px;
}
.accountpage-task{
  width:100%;
  min-height:100px;
  background-color: #813579;
  color:white;
  outline:none;
}

.accountpage-task:hover{
  width:100%;
  min-height:100px;
  background-color: #813579;
  color:white;
  border:3px solid #cb9144;
  box-shadow: 5px 3px gainsboro;
  word-wrap:normal;
}

.accountpage-button{
  width:100%;
  min-width:150px;
  aspect-ratio: 1 / 1;
  background-color: #813579;
  color:#813579;
  outline:3px solid rgb(101, 41, 95);
}

.accountpage-button:hover{
  width:100%;
  min-width:150px;
  aspect-ratio: 1 / 1;
  background-color: #813579;
  color:white;
  outline:3px solid #cb9144;
  word-wrap:normal;
}

.accountpage-buttonText{
  font-size:1em;
  font-weight:bold;
}


.accountpage-lessimportant{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.25;
  background-color: white;
  color:#813579;
  outline:3px solid #813579;
}

.accountpage-lessimportant:hover{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.25;
  background-color: #813579;
  color:white;
  outline:3px solid #cb9144;
  word-wrap:normal;
}

.accountpage-notimportant{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.25;
  background-color: gainsboro;
  color:black;
  border:none;
}

.accountpage-notimportant:hover{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.25;
  background-color: white;
  color:black;
  outline:3px solid #cb9144;
  word-wrap:normal;
}


/* Global Task Checkbox Styling */

.assign-global-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #d3d3d3;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.assign-global-checkbox input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #813579;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.assign-global-checkbox input[type="checkbox"]:checked {
  background-color: #813579;
}

.assign-global-checkbox input[type="checkbox"]:checked::before {
  content: "✔";
  color: white;
  font-size: 16px;
  position: absolute;
  left: 2px;
  top: -1px;
}

.assign-global-checkbox label {
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.adminpage-back-button {
  margin-left: auto;
  background-color: #4a90e2;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.adminpage-left, .adminpage-right {
  background-color: #d3d3d3;
  padding: 20px;
  border-radius: 20px;
  overflow-y: auto;
}

.adminpage-left {
  width: 65%;
  margin-right: 20px;
}

.adminpage-right {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.adminpage-back-button:hover {
  background-color: #843a75;
}

link{text-decoration:none;}

.taskconfirm{
  background-color: aquamarine;
  border: none;
}

.taskreject{
  background-color: orangered;
  border: none;
}

.taskclaimpoints{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.12;
  background-color: #813579;
  color:white;
  border:none;
}

.taskclaimpoints:hover{
  width:100%;
  min-height:75px;
  aspect-ratio: 1 / 0.12;
  background-color: #813579;
  color:white;
  outline:3px solid #cb9144;
  box-shadow: 5px 3px gainsboro;
  word-wrap:normal;
}



@media screen  and (min-width:992px){
  .smallHeader {
    display:none;}
}

@media screen  and (max-width:991px){
  .bigHeader {
    display:none;
  }
}

.accountRedeem-button{
  width:90%;
  aspect-ratio: 1 / 0.7;
  color:#813579;
  outline:3px solid rgb(101, 41, 95);
}

.accountRedeem-button:hover{
  width:90%;
  aspect-ratio: 1 / 0.7;
  color:white;
  outline:3px solid #cb9144;
  word-wrap:normal;
}
.accountRedeem-search-box input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  outline:3px solid rgb(101, 41, 95);
  font-size: 16px;
  background-color: white;
  color: black;
}
.accountRedeem-caption{
  background: linear-gradient(0deg, rgba(101,41,95,1) 0%, rgba(101,41,95,0.8953782196472339) 50%, rgba(101,41,95,0) 100%);  width:100%;
  color:white;
  text-decoration: none;
}


.communitySection{
  outline:none;
}

.communitySection:hover{
  outline:3px solid black;
  text-decoration:none!important;
}

/* --------------------- Dark mode --------------------- */
.navbar-dark-mode {
  background-color: #1A0F1C; 
  color: white;
}

.navbar-dark-mode .nav-link {
  color: white !important;
}

.navbar-dark-mode .navbar-brand {
  color: white !important;
}


.dark-mode {
  background-color: #1A0F1C;
  color: #ffffff;
}

.dark-mode .settings-button
.dark-mode .settings-button-round-start
.dark-mode .settings-button-round-end {
  background-color: #333;
  color: #ffffff;
}

.settings-button {
  width: 100%;
  background-color: #813579; 
  color: white;
  border: none;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.settings-button-round-start {
  width: 100%;
  background-color: #65295f; 
  color: white;
  border: none;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px 0px 0px 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.settings-button-round-end {
  width: 100%;
  background-color: #65295f; 
  color: white;
  border: none;
  padding: 15px;
  margin: 10px 0;
  border-radius: 0px 8px 8px 0px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.settings-button:hover, 
.settings-button-round-start:hover,
.settings-button-round-end:hover{
  background-color: #50204a; 
}


.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #813579; 
}

input:checked + .slider:before {
  transform: translateX(26px);
}


.footer-dark-mode {
  background-color: #1A0F1C;
  color: white;
}

.footer-dark-mode p, 
.footer-dark-mode h3, 
.footer-dark-mode a {
  color: white !important; 
}

.footer-dark-mode a:hover {
  color: #cb9144 !important; 
}

.dark-mode .settings-button,
.dark-mode .accountpage-task,
.dark-mode .accountpage-button,
.dark-mode .taskclaimpoints,
.dark-mode .share-mood-button,
.dark-mode .sleep-button {
  background-color: #f1f1f1; 
  color: #813579;
  border: 2px solid #813579;
  box-shadow: none;
}

.dark-mode .settings-button:hover,
.dark-mode .accountpage-task:hover,
.dark-mode .accountpage-button:hover,
.dark-mode .accountpage-lessimportant:hover,
.dark-mode .taskclaimpoints:hover,
.dark-mode .share-mood-button:hover,
.dark-mode .sleep-button:hover {
  background-color: #50204a; 
  border: 2px solid #cb9144; 
  color: white;
}

.dark-mode .headerStyle {
  background-color: #1A0F1C;
  color: white;
}

.dark-mode .footerStyle {
  background-color: #1A0F1C;
  color: white;
}
.dark-mode .navbar-toggler {
  border: 2px solid #f1f1f1; 
  border-radius: 5px;
  padding: 4px;
}

.dark-mode .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


.dark-mode .offcanvas {
  background-color: #1A0F1C;
  color: white; 
  border: 2px solid #cb9144; 
}


.dark-mode .offcanvas a,
.dark-mode .offcanvas .nav-link {
  color: white !important;
}


.dark-mode .offcanvas-header .btn-close {
  filter: invert(1); 
}


.dark-mode .accountRedeem-caption {
  background-color: #813579;
  color: #f1f1f1; 
  box-shadow: none; 
}

.dropdown-menu {
  background-color: white;
  color: black;
  width: 200px;
  font-size: large;
}


.dark-mode .dropdown-menu {
  background-color: #813579; 
  border: 2px solid #cb9144; 
  color: white;
}


.dark-mode .dropdown-menu .dropdown-item {
  color: white;
}

.dark-mode .dropdown-menu .dropdown-item:hover {
  background-color: #50204a;
  color: white;
}

.add-post-button {
  background-color: white;
  color: black;
  border: none;
}


.dark-mode .add-post-button {
  background-color: #f1f1f1;
  color: #813579;
  border: 2px solid #813579;
  box-shadow: none;
}

.dark-mode .add-post-button:hover {
  background-color: #813579;
  border: 2px solid #cb9144;
  color: white;
}

.communitySection {
  background: white;
  color: black;
  border: none;
}

.communitySection:hover{
  outline: 2px solid black;
}

.communitySectionNoHover {
  background: white;
  color: black;
  border: none;
}

.communitySection-dark-mode {
  background-color: #813579;
  color: #f1f1f1;
  border: 2px solid #813579;
}

.communitySectionNoHover-dark-mode {
  background-color: #813579;
  color: #f1f1f1;
  border: 2px solid #813579;
}

.communitySectionNoHover-dark-mode {
  background-color: #65295f;
  color: #f1f1f1;
  border: 2px solid #65295f;
}

.communitySection-dark-mode:hover {
  background-color: #50204a;
  outline: 2px solid #cb9144;
  color: white;
}

.dark-mode .headerName:hover {
  color: #cb9144;
}

.dark-mode .headerName {
  color: white;
}

.custom-button {
  background-color: #f1f1f1; 
  color: #813579; 
  font-size: 30px; 
  font-weight: bold;
  width: 100%; 
  max-width: 700px; 
  padding: 15px; 
  border: 3px solid #813579;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; 
}

.custom-button:hover {
  background-color: #813579; 
  color: #ffffff;
}


.custom-button {
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  max-width: 700px;
  padding: 15px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  margin: 20px auto;
}


.classic-button {
  background-color: #813579; 
  color: white;
}

.classic-button:hover {
  background-color: #813579; 
  color: white;
  border: 3px solid #cb9144;
}


.dark-mode-button {
  background-color: #f1f1f1; 
  color: #813579; 
  border: 3px solid #cb9144;
}

.dark-mode-button:hover {
  background-color: #813579; 
  color: #ffffff; 
}


/* --------------------- Dark mode --------------------- */


#password-complexity{
  background-color: gainsboro;
}

.homepageStyle{
  /* background: linear-gradient(to bottom, #50204a 0%,#65295f 73%,#65295f 73%,#65295f 100%); */
  background-color: #50204a;
  min-height: 75vh;
}