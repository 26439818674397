

.box {
    display: inlineBlock;
    margin: 30px auto 20px auto;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
}

.calendar {
    margin: 30px auto 20px auto;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.25);
}

abbr[title] {
    text-decoration: none;
}

.current-period {
    background-color: #ffcccc !important; 
    border-radius: 50%;
  }
  
  .next-period {
    background-color: #cce5ff !important;
    border-radius: 50%;
  }
  
  .ovulation-day {
    background-color: #ffd700 !important;
    border-radius: 50%;
  }
  
  .calendar {
    border-radius: 20px; /* Make the corners rounded */
    overflow: hidden; /* Ensure content respects the border radius */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add a soft shadow */
  }
  
  .react-calendar {
    border-radius: 20px !important;
    overflow: hidden;
    border: none;
  }
  
  /* Ensure individual tiles (dates) also look rounded */
  .react-calendar__tile {
    border-radius: 50%; /* Make the date cells circular */
    padding: 10px;
    transition: 0.3s;
  }
  
  .react-calendar__tile:hover {
    background-color: rgba(0, 123, 255, 0.2);
    transform: scale(1.1);
  }
  
  .react-calendar__navigation {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .react-calendar__month-view__weekdays {
    font-weight: bold;
  }
  