.share-mood-button,
.sleep-button {
  width: 100%;
  min-height: 100px;
  background-color: #813579; 
  color: white; 
  border-radius: 30px; 
  border: none; 
  padding: 20px; 
  margin-bottom: 20px; 
  text-align: center; 
  font-size: 18px; 
  font-weight: bold;
  cursor: pointer; 
  transition: all 0.3s ease; 
}

.share-mood-button:hover,
.sleep-button:hover {
  background-color: #813579;
  color: white; 
  border: 3px solid #cb9144;
  box-shadow: 5px 3px gainsboro;
  word-wrap: normal;
  transform: scale(1.02); 
}
  
 /* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content */
  .modal-content {
    background-color: #5b2c6f;
    padding: 30px; 
    border-radius: 30px;
    width: 400px; 
    text-align: center;
    color: white;
    position: relative;
  }
  
  .modal-input {
    width: 100%;
    padding: 15px;
    border-radius: 20px;
    border: none;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  .modal-done-button {
    background-color: #cb9144; 
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-done-button:hover {
    background-color: #a56f33;
  }



  /* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #5b2c6f; /* Purple background */

    border-radius: 10px;
    width: 100%;
    max-width: 400px; /* Set max width */
    text-align: center;
    color: white;
    position: relative;
  }
  
  .mood-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0 auto 20px; 
    max-width: 70%;
    justify-content: center;
  }

  
  /* Mood button styling */
  .mood-button {
    background-color: #F8F5F1;
    color: black;
    width: 100px; /* Ensure the buttons are square */
    height: 100px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    padding: 10px;
  }
  
  .mood-button.selected {
    background-color: #00c853; /* Green color for selected mood */
  }
  
  .mood-icon {
    font-size: 2em; /* Adjust size of emoji */
  }
  
  .mood-label {
    font-size: 1em;
    font-weight: bold;
  }
  
  /* Centering the Done button */
  .done-button {
    background-color: #cb9144; 
    color: white;
    padding: 15px 20px;
    width: 150px; /* Control the width of the Done button */
    text-align: center;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto; /* This will center the Done button */
  }
  
  .done-button:hover {
    background-color: #a56f33;
  }

  
  /* Centering the Done button */
  .mood-button-smile {
    width: 100%;
    background-color: #F8F5F1;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .mood-button {
    background-color: #F8F5F1;
    color: black;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px; /* Set the size of the square */
    height: 100px; /* Make the button a square */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .mood-button.selected {
    background-color: #00c853; /* Green color for selected mood */
  }
  
  .mood-icon {
    font-size: 2em; /* Adjust size of emoji */
  }
  
  .mood-label {
    font-size: 1em;
    font-weight: bold;
  }
  
.done-button {
    background-color: #cb9144; 
    color: white;
    padding: 10px 20px;
    width:70%;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.done-button:hover {
    background-color: #a56f33;
  }

  
  /* Centering the Done button */
  .mood-button-smile {
    width: 100%;
    background-color: #F8F5F1;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .mood-button {
    background-color: #F8F5F1;
    color: black;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px; /* Set the size of the square */
    height: 100px; /* Make the button a square */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .mood-button.selected {
    background-color: #9bdcc0 ; /* Green color for selected mood */
  }
  
  .mood-icon {
    font-size: 2em; /* Adjust size of emoji */
  }
  
  .mood-label {
    font-size: 1em;
    font-weight: bold;
  }
  
.done-button {
    background-color: #cb9144; 
    color: white;
    padding: 10px 20px;
    width:70%;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.done-button:hover {
    background-color: #a56f33;
  }

  
  /* Centering the Done button */
  .mood-button-smile {
    width: 100%;
    background-color: #F8F5F1;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
