.btn-submit, .btn-reset {
    flex: 1;
}

.min-height-reset-container{
    min-height: 30px;
}

.rating-group-container{
    max-width: 450px;
}

.rating-bar-left,
.rating-bar-right {
    font-size: 0.9rem;
    font-weight: 600;
    color: #333;
    min-width: 45px;
    text-align: center;
}

.rating-bar-boxes {
    display: flex;
    flex: 1;
    gap: 5px;
}

.rating-bar-labels {
    display: flex;
    justify-content: space-between;
}

.rating-group {
    margin-bottom: 20px;
    background-color: white;
    padding: 5px 10px;
    border: 1px solid #cacaca;
    border-radius: 5px;
    width: 100%;
}



.rating-group-title {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}




.rating-label-container {
    margin-bottom: 8px;
}

.rating-label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.rating-description {
    font-size: 0.9rem;
    color: #555;
    margin: 4px 0 0;
}

.rating-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.rating-box {
    flex: 1;
    height: 50px;
    border-radius: 6px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
}


.rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 768px;
    margin: auto;
    padding: 25px;
    background: linear-gradient(145deg, #fdfdfd, #f0f0f0);
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.rating-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.rating-row {
    width: 100%;
    display: flex;
    flex-direction: column;
}