.cup-container {
    position: relative;
    width: 100px;
    height: 100px;
    border: 5px solid #000;
    border-top: none;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
    margin: 0 auto;
}

.max-width-input-group{
  max-width: 300px;
  margin: auto;
}

.hydration-group {
  margin-bottom: 20px;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100%;
}

.water {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    background: lightblue;
    transition: height 0.5s ease-in-out;
}


.circular-chart {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle {
  stroke-linecap: round;
  transition: stroke-dasharray 0.5s ease;
}

.small-input {
  width: 80px; /* Adjust as needed */
}

.top-custom{
  bottom: 100px;
}