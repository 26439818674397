.redeem-cover{
    color:white;
    background:  linear-gradient(to bottom, rgba(80,32,74,1) 0%,rgba(101,41,95,0.5) 73%, rgba(101,41,95,0) 100%);
    width:100%;
    min-height: 200px;
}

.accountRedeem-button{
    width:100%;
    aspect-ratio: 1 / 0.7;
    color:#65295f;
    outline:3px solid rgb(101, 41, 95);
  }
  
  .accountRedeem-button:hover{
    width:100%;
    aspect-ratio: 1 / 0.7;
    color:white;
    outline:3px solid #cb9144;
    word-wrap:normal;
  }
  .accountRedeem-search-box input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    outline:3px solid rgb(101, 41, 95);
    font-size: 16px;
    background-color: white;
    color: black;
  }
  .accountRedeem-caption{
    background: linear-gradient(0deg, rgba(101,41,95,1) 0%, rgba(101,41,95,0.8953782196472339) 50%, rgba(101,41,95,0) 100%);  width:100%;
    color:white;
    text-decoration: none;
  }