.btn-success {
    background-color: #813579 !important;
    border-color: #813579 !important;
    color: white;
}

.btn-success:hover {
    background-color: #652a5f !important;
    border-color: #652a5f !important;
    color: white;
}

.btn-success:active {
    background-color: #652a5f !important;
    border-color: #652a5f !important;
    color: white;
}

/* gold */
.btn-danger {
    background-color: #cb9144 !important;
    border-color: #cb9144 !important;
    color: white;
}

.btn-danger:hover {
    background-color: #a37538 !important;
    border-color: #a37538 !important;
    color: white;
}

.btn-danger:active {
    background-color: #a37538 !important;
    border-color: #a37538 !important;
    color: white;
}

/* white */
.btn-warning {
    background-color: white !important;
    border-color: white !important;
    color: black;
}

.btn-warning:hover {
    background-color: #e4e0e0 !important;
    border-color: #e4e0e0 !important;
    color: black;
}

.btn-warning:active {
    background-color: #e4e0e0 !important;
    border-color: #e4e0e0 !important;
    color: black;
}

.shadow-light {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow-heavy {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.shadow-light-negative {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.background-white {
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.remove-round-right {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.remove-round-left {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.TextInput {
    border-radius: 0;
    border: none;
    border-bottom: 3px solid white;
    padding: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #813579;
    margin-bottom: 15px;
}

.TextInput:focus {
    border-radius: 0;
    border: none;
    border-bottom: 5px solid #813579;
    padding: 5px;
    outline: none;
}